<template>
  <div>
    <Auth />
  </div>
</template>

<script>
import Auth from '@/components/enter/Auth'

export default {
  name: 'Login',
  components: {
    Auth
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/variables';
</style>
